export const socialData = [
  {
    href: 'https://github.com/albertovillalobos',
    className: 'github',
    fa: 'fa fa-github-alt',
  },
  {
    href: 'mailto:alberto@albertovillalobos.me',
    className: 'email',
    fa: 'fa fa-envelope-o',
  },
];

export const aboutMe1 = `I'm a full-stack developer with a passion for coding, hacking, and solving problems. I started this journey in my early teenage years to hack video-games, realizing that with coding I could hack, improve and create awesome stuff. My passion quickly evolved into creating software to help my parents' business and into pursuing a degree in CS in the US, winning Hackathons and doing startups.`;

export const aboutMe2 = `I'm now a seasoned hacker, full-stack and mobile developer with years of experience in several languages. My area of expertise, and the area I currently have the most fun is in Full-Stack Engineering, building complex serverless apps in Next, React, and GraphQL.`;

export const particularSkills = [
  {
    name: 'Languages',
    skills: [
      'Javascript',
      'Typescript',
      'HTML',
      'CSS',
      'Ruby',
      'C#',
      'JAVA',
      'C/C++',
      'Swift',
      'PHP',
      'Reddis'
    ],
  },
  {
    name: 'Favorites',
    skills: [
      'React',
      'Next',
      'NodeJS',
      'GraphQL',
      'Serverless',
      'CI/CD',
      'Tailwind',
      'Flexbox',
      'Redux',
      'Webpack',
      'React-Native',
      'Rails',
      'Express',
      'Mongoose',
    ],
  },
  {
    name: 'Platforms',
    skills: ['Amazon AWS', 'Digital Ocean', 'FireBase'],
  },
  {
    name: 'Database',
    skills: ['MongoDB', 'MySQL', 'Microsoft SQL'],
  },
];
